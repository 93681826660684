import { IUser, PaginatedV2Result, SortTypes, Tuple12, NameUuid  } from "@connect/Interfaces";

export interface ActionSet {
	uuid: string;
	name: string;
	type: TriggerType;
	data: Trigger[];
	createdAt: string;
	createdBy?: Partial<IUser>;
	updatedAt: string;
	updatedBy?: Partial<IUser>;
	playlists?: NameUuid[];
}

export const ActionSetTriggers = {
	motion: {
		icon: "running",
		name: "motion",
		title: "Motion"
	},
	face: {
		icon: "smile",
		name: "face",
		title: "Face"
	},
	alarm_0: {
		icon: "bell",
		name: "alarm_0",
		title: "Alarm 1"
	},
	alarm_1: {
		icon: "bell",
		name: "alarm_1",
		title: "Alarm 2"
	},
	button_front: {
		icon: "circle",
		name: "button_front",
		title: "Button"
	},
	person: {
		icon: "male",
		name: "person",
		title: "Person"
	},
	network_request: {
		icon: "network-wired",
		name: "network_request",
		title: "Network In"
	}
}

export const ActionSetActions = {
	led_front: {
		icon: "lightbulb-on",
		name: "led_front",
		title: "LED On"
	},
	led_button: {
		icon: "circle",
		name: "led_button",
		title: "Button LED"
	},
	audio_level: {
		icon: "volume-up",
		name: "audio_level",
		title: "Audio Level"
	},
	ad: {
		icon: "ad",
		name: "ad",
		title: "Play Ad"
	},
	camera: {
		icon: "video",
		name: "camera",
		title: "Full Camera"
	},
	alarm_out: {
		icon: "bell",
		name: "alarm_out",
		title: "Alarm Out"
	},
	lightbar: {
		icon: "magic",
		name: "lightbar",
		rotate: 45,
		title: "Lightbar"
	},
	network_request: {
		icon: "network-wired",
		name: "network_request",
		title: "Network Out"
	},
	default: {
		icon: "arrow-to-left",
		name: "default",
		title: "Default Playlist"
	}
}

export type ActionType = "ad" | "led_front" | "led_button" | "audio_level" | "camera" | "alarm_out" | "lightbar" |
"network_request" | "default" | "";

export type TriggerType = "motion" | "alarm_0" | "alarm_1" | "button_front" | "face" | "person" |
"network_request" | "";

export interface Trigger {
	type: TriggerType;
	actions: TriggeredAction[];
	duration: number;
	throttle: number;
	dwell?: TriggerDwell;
	boxing?: TriggerBoxing;
	continuous?: boolean;
	parameter?: string;
}

export interface TriggerDwell {
	enabled: boolean;
	duration: number;
}

export interface TriggerBoxing {
	enabled: boolean;
	color: string;
}

export interface TriggeredAction {
	type: ActionType;
	adUuid?: string;
	flashing?: boolean;
	audioLevel?: number;
	preset?: number;
	method?: "get" | "post";
	uri?: string;
}

export function generateDefaultAction(actionType: ActionType): TriggeredAction {
	switch (actionType) {
		case "led_front":
			return {
				type: actionType,
				flashing: false
			};
		case "led_button":
			return {
				type: actionType,
				flashing: false
			}
		case "audio_level":
			return {
				type: actionType,
				audioLevel: 0
			};
		case "lightbar":
			return {
				type: actionType,
				preset: 0
			};
		case "network_request":
			return {
				type: actionType,
				method: "get",
				uri: ""
			};
		case "ad":
		case "camera":
		case "alarm_out":
		default:
			return {
				type: actionType
			}
	}
}

export interface LightbarAnimation {
	frames: LightbarFrame[];
}

export interface LightbarFrame {
	colors: Tuple12<string>;
	duration: number;
}

export interface ActionSetsResult extends PaginatedV2Result {
	data: ActionSet[];
}

export interface ActionSetDispatch {
	actionSet: ActionSet;
}

export interface ActionSetsDispatch {
	actionSets: ActionSet[];
	reset?: boolean;
}

export interface ActionSetCollectionArgs {
	sort?: SortTypes;
	filter?: string;
}

export interface TriggerActionTypeArgs {
	triggerType: TriggerType;
	actionType: ActionType;
}

export enum ComponentsViewTypes { ACTION = "action", TRIGGER = "trigger" };

export const MAX_ACTIONS = Object.keys(ActionSetActions).length - 1;
import * as React from "react";
import { connect } from "react-redux";

import { ActionSetActions, CustomCSS } from "@connect/Interfaces";
import { ActionPropertiesPanelContent,
	ActionPropertiesPanelContentProps } from "Components/Actions/ActionPropertiesPanelContent";
import { Input, Select } from "Components/Global/Common";
import { updateSelectedActionMethod, updateSelectedActionURI } from "Data/Actions/ActionsAsync";
import inputValidation from "Data/Objects/Validation";
import { getSelectedActionMethod, getSelectedActionType, getSelectedActionURI } from "Data/Selectors/ActionBuilder";
import { mapStateToProps as superMapStateToProps } from "Components/Actions/ActionPropertiesPanelContent";


const { Option } = Select;

const mapStateToProps = (state) => {
	const superProps = superMapStateToProps(state);
	return {
		...superProps,
		activeActionType: getSelectedActionType(state),
		method: getSelectedActionMethod(state),
		uri: getSelectedActionURI(state)
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateURI: (uri: string) => dispatch(updateSelectedActionURI(uri)),
		updateMethod: (method: "get" | "post") => dispatch(updateSelectedActionMethod(method))
	}
}

interface NetworkActionPropertiesPanelContentProps extends ActionPropertiesPanelContentProps {
	method: "get" | "post";
	uri: string;
	updateURI: (uri: string) => void;
	updateMethod: (method: "get" | "post") => void;
}

export class NetworkActionPropertiesPanelContent
	extends ActionPropertiesPanelContent<NetworkActionPropertiesPanelContentProps> {
	constructor(props: NetworkActionPropertiesPanelContentProps) {
		super(props);

		const { icon, title } = ActionSetActions.network_request;

		this.icon = icon;
		this.title = title;
		this.styles = {
			...this.styles,
			methodSelect: {
				width: "100%",
				marginBottom: 10
			}
		}
	}

	styles: CustomCSS;

	render() {
		return this.renderContainer(this.renderContent());
	}

	renderContent() {
		const { methodSelect } = this.styles;
		const { method, updateMethod, uri, updateURI } = this.props;

		return (
			<div>
				<div>Method:</div>
				<Select
					key={ method }
					defaultValue={ method }
					onChange={ updateMethod }
					style={ methodSelect }
				>
					<Option value="get">GET</Option>
					<Option value="post">POST</Option>
				</Select>
				<div>URL:</div>
				<Input
					key={ uri }
					id="network-action-uri"
					value={ uri }
					saveCallback={ updateURI }
					validator={ inputValidation.uri }
				/>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkActionPropertiesPanelContent);